<template>
  <!-- 会员中心 -->
  <div class="memberManagement">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="editAndAddRow">
          添加
        </el-button>
      </div>
      <Table :item-data="itemData" :list-data="listData" :operation-button="operationButton" :loading="loading" />
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getdata" />
    </div>
    <!-- 弹出的详情框跟审核框 -->
    <!-- <el-dialog title="会员信息" :visible.sync="editFormVisible" width="62%" @click="editFormVisible = false" class="dialog" :close-on-click-modal="false">
      <el-tabs v-model="activeName" type="card">
        基本信息
        <el-tab-pane label="基本信息" name="first">
          <el-descriptions class="margin-top" title="基本信息" :column="2" :size="''">
            <template v-if="controlButton" slot="extra">
              <el-button @click="confirmAudit" type="primary" size="small">审核</el-button>
            </template>
            <el-descriptions-item :span="2" label="公司名称">{{ memberInformation.cmpName }}</el-descriptions-item>
            <el-descriptions-item label="公司角色">{{ memberInformation.cmpRole }}</el-descriptions-item>
            <el-descriptions-item label="统一社会信用代码">{{ memberInformation.cmpUnicode }}</el-descriptions-item>
            <el-descriptions-item label="公司简称">{{ memberInformation.cmpShortName }}</el-descriptions-item>
            <el-descriptions-item label="注册地址">{{ memberInformation.cmpAddress }}</el-descriptions-item>
            <el-descriptions-item label="注册资本(万元)">{{ memberInformation.cmpRegFund }}</el-descriptions-item>
            <el-descriptions-item label="实收资本(万元)">{{ memberInformation.cmpRealFund }}</el-descriptions-item>
            <el-descriptions-item label="成立日期">{{ memberInformation.cmpDate }}</el-descriptions-item>
            <el-descriptions-item label="法定代表人">{{ memberInformation.leadName }}</el-descriptions-item>
            <el-descriptions-item label="实际控制人">{{ memberInformation.cmpOwner }}</el-descriptions-item>
            <el-descriptions-item label="公司类型">{{ memberInformation.cmpType }}</el-descriptions-item>
            <el-descriptions-item label="公司状态">{{ memberInformation.businessState }}</el-descriptions-item>
            <el-descriptions-item label="公司认证状态">{{ memberInformation.authState == '0' ? '未认证' : '已认证' }}
            </el-descriptions-item>
            <el-descriptions-item label="经营期限截止日">{{ memberInformation.cmpCloseDate }}</el-descriptions-item>
            <el-descriptions-item label="E签宝认证">{{ memberInformation.authFlowId }}</el-descriptions-item>
            <el-descriptions-item label="E签宝认证链接">{{ memberInformation.authUrl }}</el-descriptions-item>
            <el-descriptions-item label="电子签章账号">{{ memberInformation.cmpAccountId }}</el-descriptions-item>
            <el-descriptions-item label="当前签章人">{{ memberInformation.currEsignUser }}</el-descriptions-item>
            <el-descriptions-item label="状态">
              <div v-for="item in accountStatus" :key="item.id">
                <span v-if="memberInformation.cmpState == item.id">{{ item.name }}</span>
              </div>
            </el-descriptions-item>
            <el-descriptions-item :span="2" label="登记机关">{{ memberInformation.cmpRegOrg }}</el-descriptions-item>
            <el-descriptions-item labelStyle="width:200px" :span="2" label="经营范围">{{ memberInformation.cmpBisScope }}
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions class="margin-top" title="账户信息" :column="2" :size="''">
            <el-descriptions-item label="公司编码">{{ memberInformation.cmpCode }}</el-descriptions-item>
            <el-descriptions-item label="登录账号">{{ memberInformation.userCode }}</el-descriptions-item>
            <el-descriptions-item label="用户姓名">{{ memberInformation.userName }}</el-descriptions-item>
            <el-descriptions-item label="用户手机号">{{ memberInformation.phoneNo }}</el-descriptions-item>
            <el-descriptions-item label="管理员身份证">{{ memberInformation.cardNo }}</el-descriptions-item>
            <el-descriptions-item label="管理员邮箱">{{ memberInformation.email }}</el-descriptions-item>
            <el-descriptions-item label="传真">{{ memberInformation.cmpFax }}</el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
        电子资料
        <el-tab-pane label="电子资料" name="second">
          <el-descriptions class="margin-top" title="电子资料" :column="1" direction="vertical">
            <template v-if="controlButton" slot="extra">
              <el-button @click="confirmAudit" type="primary" size="small">审核</el-button>
            </template>
            <el-descriptions-item label="营业执照">
              <div class="electronicMaterial">
                <img :src="$fileUrl + memberInformation.businessLicense" :onerror="$errImage" alt="电子资料" />
                <a v-if="memberInformation.businessLicense" target="_blank" :href="$fileUrl + memberInformation.businessLicense">查看</a>
              </div>
            </el-descriptions-item>
            <el-descriptions-item label="法人身份证">
              <div class="electronicMaterial">
                <img :src="$fileUrl + memberInformation.leadIdcardFront" :onerror="$errImage" alt="正面" />
                <a v-if="memberInformation.leadIdcardFront" target="_blank" :href="$fileUrl + memberInformation.leadIdcardFront">查看</a>
              </div>
              <div class="electronicMaterial">
                <img :src="$fileUrl + memberInformation.leadIdcardBack" :onerror="$errImage" alt="反面" />
                <a v-if="memberInformation.leadIdcardBack" target="_blank" :href="$fileUrl + memberInformation.leadIdcardBack">查看</a>
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
        法人信息
        <el-tab-pane label="法人信息" name="third">
          <el-descriptions class="margin-top" title="法人信息" :column="2" :size="''">
            <template v-if="controlButton" slot="extra">
              <el-button @click="confirmAudit" type="primary" size="small">审核</el-button>
            </template>
            <el-descriptions-item label="法人姓名">{{ memberInformation.leadName }}</el-descriptions-item>
            <el-descriptions-item label="法人身份证号码">{{ memberInformation.leadPaperid }}</el-descriptions-item>
            <el-descriptions-item label="身份证有效期开始日">{{ memberInformation.leadIdcardSdate }}</el-descriptions-item>
            <el-descriptions-item label="身份证有效期截止日">{{ memberInformation.leadIdcardEdate }}</el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
        账号信息
        <el-tab-pane label="账户信息" name="fourth">
          <AccountInformation :cmpCode="cmpCode"></AccountInformation>
        </el-tab-pane>
        审核历史
        <el-tab-pane label="审核历史" name="fourth2">
          <el-timeline :reverse="true">
            <el-timeline-item v-for="(activity, index) in approveArr" :key="index"
              :timestamp="timestampToTime(activity.approveTime)" placement="top">
              <el-card>
                <h3>{{ activity.nodeName }}</h3>
                <p>审核人：{{ activity.userName }}</p>
                <p>审核状态：
                  <span v-if="activity.approveState === '99'">审核通过</span>
                  <span v-else-if="activity.approveState === '01'">带补录</span>
                  <span v-else-if="activity.approveState === '02'">待平台审核</span>
                  <span v-else-if="activity.approveState === '-1'">平台驳回</span>
                </p>
                <p>原因：{{ activity.approveExplain }}</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
          <el-empty v-if="approveArr && approveArr.length === 0" description="暂无数据"></el-empty>
        </el-tab-pane>
      </el-tabs>
    </el-dialog> -->
    <!-- 审核弹窗 -->
    <!-- <Dialog ref="dialog" dialogWidth="40%"  @getFormData="getFormData" :formItem="formItem"></Dialog> -->
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import { cmpBaseinfoPage, cmpBaseinfoEnableOrDisable } from '@/api/memberManagement'
import { accountStatus } from '@/systemConfiguration/index'
import { timestampToTime } from '@/utils/util'
export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      accountStatus,
      activeName: 'first',
      editFormVisible: false,
      controlButton: false,
      cmpCode: '',
      enterpriseRole: this.$store.getters.getDictionaryItem('SYS_TYPE'),
      formItem: [
        {
          label: '审核状态',
          type: 'select',
          value: 'approveResult',
          optionLabel: 'VARIABLE_NAME',
          optionId: 'VARIABLE_CODE',
          child: []
        },
        {
          label: '原因',
          type: 'input',
          value: 'approveAdvise',
          rules: 'none'
        }
      ],
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      memberInformation: {},
      formItemArr: [
        { type: 'input', label: '公司名称', value: 'cmpName' },
        { type: 'input', label: '统一社会信用代码', value: 'cmpUnicode', width: '140px' },
        { type: 'input', label: '法人姓名', value: 'leadName' },
        { type: 'select', label: '授权系统', value: 'cmpRole', pLabel: 'dictName', pValue: 'dictId', child: this.$store.getters.getDictionaryItem('SYS_TYPE') },
        { type: 'input', label: '管理员账号', value: 'userCode', width: '88px' },
        { type: 'input', label: '管理员手机号', value: 'phoneNo', width: '102px' },
        { type: 'select', label: '账户状态', value: 'cmpState', pLabel: 'name', pValue: 'id', child: accountStatus }
      ],
      itemData: [
        {
          label: '公司名称',
          prop: 'cmpName',
          width: 180
        },
        {
          label: '统一社会信用代码',
          prop: 'cmpUnicode',
          width: 140
        },
        {
          label: '法人姓名',
          prop: 'leadName',
          width: 80
        },
        {
          label: '授权系统',
          prop: 'cmpRole',
          width: 180,
          html: true,
          limited: true
        },
        {
          label: '管理员账号',
          prop: 'userCode',
          width: 140
        },
        {
          label: '管理员手机号',
          prop: 'phoneNo',
          width: 140
        },
        {
          label: '客户来源',
          prop: 'registerSource',
          width: 180
        },
        {
          label: '公司认证状态',
          prop: 'isAuthState',
          width: 140
        },
        {
          label: '审核状态',
          prop: 'isCmpState',
          width: 140,
          html: true,
          limited: true
        },
        {
          label: '公司账号状态',
          prop: 'isLock',
          child: [{ value: '1', label: '禁用' }, { value: null, label: '禁用' }, { value: '0', label: '启用' }],
          width: 140
        }
      ],
      listData: [],
      operationButton: [
        { bType: 'primary', label: '关联企业', handleEvent: this.relevanceEnterprises, child: [{ val: 'isShowRelevanceBtn' }] },
        { bType: 'primary', label: '编辑', handleEvent: this.editAndAddRow, child: [{ val: 'editButton' }] },
        { child: [{ num: '02', val: 'cmpState' }, { val: 'currentNodeId' }, { val: 'activiteId' }], bType: 'primary', label: '审核', handleEvent: this.auditRow },
        { child: [{ num: '04', val: 'cmpState' }, { val: 'currentNodeId' }, { val: 'activiteId' }], bType: 'primary', label: '平台终审', handleEvent: this.auditRow },
        { child: [{ num: '99', val: 'cmpState' }, { num: '1', val: 'isLock' }], bType: 'success', label: '启用', handleEvent: this.disableEnabledRow },
        { child: [{ num: '99', val: 'cmpState' }, { num: '0', val: 'isLock' }], bType: 'danger', label: '禁用', handleEvent: this.disableEnabledRow },
        { bType: 'primary', withParameters: '详情', label: '详情', handleEvent: this.auditRow }
      ],
      total: 0,
      loading: false,
      approveArr: [],
      resourceByTaskObj: {}
    }
  },
  methods: {
    timestampToTime,
    // 获取数据
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      }
      cmpBaseinfoPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        // 因为角色名称是个数组  所以每一条需要取他们的相同点进行加载
        this.listData.forEach(item => {
          if (item.cmpRoleList.includes('10') || item.cmpRoleList.includes('11')) {
            item.isShowRelevanceBtn = true
          } else {
            item.isShowRelevanceBtn = false
          }
          // 控制编辑按钮   在审核中和禁用下不能有编辑按钮
          if (item.cmpState === '02' || item.cmpState === '04' || item.isLock === 1) {
            item.editButton = false
          } else {
            item.editButton = true
          }
          // 获取registerSource
          item.registerSource = item.registerSource === '00' ? '后台添加' : '自主注册'
          // 公司认证状态
          item.isAuthState = item.authState === '1' ? '已认证' : '未认证'
          // 审核状态
          switch (item.cmpState) {
            case '02':
              item.isCmpState = '审核中<br>待平台初审审核'
              break
            case '-1':
              item.isCmpState = '审核不通过'
              break
            case '04':
              item.isCmpState = '审核中<br>待平台终审审核'
              break
            case '99':
              item.isCmpState = '审核通过'
              break
            default:
              item.isCmpState = ''
              break
          }
          // 获取角色
          item.cmpRole = ''
          if (item.cmpRoleList && item.cmpRoleList.length > 0) {
            this.enterpriseRole.forEach(val => {
              if (item.cmpRoleList.includes(val.dictId)) {
                item.cmpRole += `<span>${val.dictName}</span><br>`
                val.dictName + ' '
              }
            })
          }
        })
        this.total = res.data.total
      })
    },
    // 点击详情或者审核按钮的时候
    auditRow(row, val) {
      this.$store.commit('setResourceByTaskObj', { ...row })
      this.$router.push({
        path: '/memberManagement/memberDetails',
        query: {
          cmpCode: row ? row.cmpCode : null,
          type: val
        }
      })
      // this.editFormVisible = true
      // this.activeName = 'first'
      // this.cmpCode = row.cmpCode
      // this.resourceByTaskObj = { ...row }
      // // 有val就是详情了   没有的话就是审核
      // cmpBaseinfoDetail(row.cmpCode, res => {
      //   this.memberInformation = { ...res.data }
      //   // 因为角色名称是个数组  所以需要取他们的相同点进行加载
      //   if (this.memberInformation.cmpRoleList && this.memberInformation.cmpRoleList.length > 0) {
      //     this.memberInformation.cmpRole = ''
      //     this.enterpriseRole.forEach(val => {
      //       if (this.memberInformation.cmpRoleList.includes(val.id)) this.memberInformation.cmpRole += val.name + ' '
      //     })
      //   }
      // })
      // // 审核流程
      // cmpApprovePage({ pageNum: 1, cmpCode: row.cmpCode, pageSize: 100000 }, res => {
      //   this.approveArr = [...res.data.pageData]
      // })
      // this.controlButton = val ? false : true
    },
    // 关联企业
    relevanceEnterprises(rows) {
      this.$router.push({
        path: '/memberManagement/relevanceEnterprises',
        query: {
          coreCmpUnicode: rows.cmpUnicode,
          cmpName: rows.cmpName
        }
      })
    },
    // 审核提交
    // getFormData(value) {
    //   let obj = {
    //     ...value,
    //     applyCode: this.memberInformation.cmpCode,
    //     activiteId: this.resourceByTaskObj.activiteId,
    //     cmpState: this.memberInformation.cmpState,
    //     currentNodeId: this.resourceByTaskObj.currentNodeId
    //   }
    //   cmpBaseinfoAudit(obj, () => {
    //     this.$refs.dialog.editFormVisible = false
    //     this.$message.success('成功！')
    //     this.getdata(true)
    //   })
    // },
    // 确认审核按钮
    // confirmAudit() {
    //   this.editFormVisible = false
    //   this.$refs.dialog.editFormVisible = true
    //   getResourceByTask(this.resourceByTaskObj, res => {
    //     this.formItem.forEach(item => {
    //       if (item.value === 'approveResult') {
    //         item.child = [...res.data]
    //       }
    //     })
    //   })
    // },
    // 跳转编辑页面
    editAndAddRow(row) {
      if (row.cmpCode) {
        this.$router.push({ path: '/memberManagement/editMember', query: { cmpCode: row.cmpCode }})
        return
      }
      this.$router.push({ path: '/memberManagement/addMember' })
    },
    // 禁用与启用
    disableEnabledRow(row) {
      this.$confirm('此操作将修改此条信息状态, 是否继续?请谨慎', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        cmpBaseinfoEnableOrDisable({ cmpCode: row.cmpCode, isLock: row.isLock === '0' ? '1' : '0' }, () => {
          this.$message.success('成功')
          this.getdata(true)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    }
  }
}
</script>

<style scoped>
.electronicMaterial {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.electronicMaterial img {
  height: 120px;
  width: 180px;
  border-radius: 2px;
}

.electronicMaterial a {
  color: rgb(26, 7, 136);
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.el-tabs>>>.el-tabs__content {
  padding: 0 16px 32px;
}
</style>
